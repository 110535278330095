export const brazilianStates = [
    {sigla: 'AC', nome: 'Acre'},
    {sigla: 'AL', nome: 'Alagoas'},
    {sigla: 'AP', nome: 'Amapá'},
    {sigla: 'AM', nome: 'Amazonas'},
    {sigla: 'BA', nome: 'Bahia'},
    {sigla: 'CE', nome: 'Ceará'},
    {sigla: 'DF', nome: 'Distrito Federal'},
    {sigla: 'ES', nome: 'Espírito Santo'},
    {sigla: 'GO', nome: 'Goiás'},
    {sigla: 'MA', nome: 'Maranhão'},
    {sigla: 'MT', nome: 'Mato Grosso'},
    {sigla: 'MS', nome: 'Mato Grosso do Sul'},
    {sigla: 'MG', nome: 'Minas Gerais'},
    {sigla: 'PA', nome: 'Pará'},
    {sigla: 'PB', nome: 'Paraíba'},
    {sigla: 'PR', nome: 'Paraná'},
    {sigla: 'PE', nome: 'Pernambuco'},
    {sigla: 'PI', nome: 'Piauí'},
    {sigla: 'RJ', nome: 'Rio de Janeiro'},
    {sigla: 'RN', nome: 'Rio Grande do Norte'},
    {sigla: 'RS', nome: 'Rio Grande do Sul'},
    {sigla: 'RO', nome: 'Rondônia'},
    {sigla: 'RR', nome: 'Roraima'},
    {sigla: 'SC', nome: 'Santa Catarina'},
    {sigla: 'SP', nome: 'São Paulo'},
    {sigla: 'SE', nome: 'Sergipe'},
    {sigla: 'TO', nome: 'Tocantins'},
];


export function formatDateString(dateString) {

    const dateObj = new Date(dateString);

    const newDateString = dateObj.toISOString();

    const year = newDateString.slice(0, 4);
    const month = newDateString.slice(5, 7);
    const day = newDateString.slice(8, 10);

    return `${day}/${month}/${year}`;
}

export function formatDateTimeString(dateString) {

    const dateObj = new Date(dateString);

    dateObj.setHours(dateObj.getHours() - 3);


    const newDateString = dateObj.toISOString();

    const year = newDateString.slice(0, 4);
    const month = newDateString.slice(5, 7);
    const day = newDateString.slice(8, 10);

    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}/${month}/${year} ${hours}:${minutes}`;

}

export function formatDateToAmericanFormat(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    return year + '-' + month + '-' + day;
}

export function formatDateToString(date) {
    date = adjustDateToServerTimeZone(date);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return day + '/' + month + '/' + year;
}

export function formatDateTimeToString(date) {
    date = adjustDateToBrowserTimeZone(date);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    let hours = date.getHours();
    let minutes = date.getMinutes();

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return day + '/' + month + '/' + year+ ' '+ hours + ':'+minutes;
}

export function calculateDaysDifference(date1, date2) {
    var date1Millis = date1.getTime();
    var date2Millis = date2.getTime();
    var differenceMillis = date2Millis - date1Millis;
    var differenceInDays = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
    return differenceInDays;
}

export function plusDays(date, days) {
    date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;

}

export function removeTimeFromDate(date) {
    date.setHours(0, 0, 0, 0);
    return date;
}

export function getDate(dateString) {

    const dateObj = new Date();
    dateObj.setUTCHours(0, 0, 0, 0);

    const newDateString = dateString;

    let year = newDateString.slice(0, 4);
    let month = Number(newDateString.slice(5, 7)) - 1;
    const day = newDateString.slice(8, 10);

    if (month === -1) {
        month = 12;
        year--;
    }

    dateObj.setFullYear(year, month, day);

    return dateObj;
}

export function adjustDateToBrowserTimeZone(date) {

    return date;
}

export function adjustDateToServerTimeZone(date) {

    return date;
}

export function getOnlyDate(date) {
    const clonedDate = new Date(date);
    clonedDate.setHours(0, 0, 0, 0);
    return clonedDate;
}

export const SaleStatus = {
    OPENING: 'OPENING',
    SENDING_THE_FILE: 'SENDING_THE_FILE',
    PRODUCTION: 'PRODUCTION',
    CONCLUDED: 'CONCLUDED',
    CANCELED: 'CANCELED'
};

export const statusTranslate = {
    ALL: { label: 'Todos', color: '#ffffff' },
    IN_PROGRESS: { label: 'Em andamento', color: '#ffffff' },
    OPENING: { label: 'Abertura', color: '#1E90FF' },
    SENDING_THE_FILE: { label: 'Enviando o arquivo', color: '#81c2ff' },
    PRODUCTION: { label: 'Produção', color: '#ecd98a' },
    CONCLUDED: { label: 'Concluído', color: 'rgba(52,168,52,0.73)' },
    CANCELED: { label: 'Cancelado', color: '#949494' }
};

export const renderSaleOptions = () => {
    return Object.keys(SaleStatus).map(status => (
        <option style={{backgroundColor: '#FFFFFF'}} key={status} value={status}>
            {statusTranslate[status].label}
        </option>
    ));
};

export const PaymentCondition = {
    FULL_PAYMENT: 'FULL_PAYMENT',
    INSTALLMENT_PAYMENT: 'INSTALLMENT_PAYMENT',
    INSTALLMENT_WITH_DOWN_PAYMENT: 'INSTALLMENT_WITH_DOWN_PAYMENT'
};

export const paymentConditionTranslate = {
    FULL_PAYMENT: { label: 'À vista'},
    INSTALLMENT_PAYMENT: { label: 'A prazo'},
    INSTALLMENT_WITH_DOWN_PAYMENT: { label: 'A prazo com entrada'}
};

export const renderPaymentConditionOptions = () => {
    return Object.keys(PaymentCondition).map(paymentCondition => (
        <option key={paymentCondition} value={paymentCondition}>
            {paymentConditionTranslate[paymentCondition].label}
        </option>
    ));
};

export const UserPermission = {
    LIST_SERVICE: 'LIST_SERVICE',
    CREATE_SERVICE: 'CREATE_SERVICE',
    EDIT_SERVICE: 'EDIT_SERVICE',
    REMOVE_SERVICE: 'REMOVE_SERVICE',

    LIST_SECTOR: 'LIST_SECTOR',
    CREATE_SECTOR: 'CREATE_SECTOR',
    EDIT_SECTOR: 'EDIT_SECTOR',
    REMOVE_SECTOR: 'REMOVE_SECTOR',

    LIST_PAYMENT_TERM: 'LIST_PAYMENT_TERM',
    CREATE_PAYMENT_TERM: 'CREATE_PAYMENT_TERM',
    EDIT_PAYMENT_TERM: 'EDIT_PAYMENT_TERM',
    REMOVE_PAYMENT_TERM: 'REMOVE_PAYMENT_TERM',

    LIST_RECEIVABLE: 'LIST_RECEIVABLE',
    CREATE_RECEIVABLE: 'CREATE_RECEIVABLE',
    EDIT_RECEIVABLE: 'EDIT_RECEIVABLE',
    REMOVE_RECEIVABLE: 'REMOVE_RECEIVABLE',
    REVERSE_RECEIVABLE: 'REVERSE_RECEIVABLE',

    LIST_PAYMENT_METHOD: 'LIST_PAYMENT_METHOD',
    CREATE_PAYMENT_METHOD: 'CREATE_PAYMENT_METHOD',
    EDIT_PAYMENT_METHOD: 'EDIT_PAYMENT_METHOD',
    REMOVE_PAYMENT_METHOD: 'REMOVE_PAYMENT_METHOD',

    LIST_USER: 'LIST_USER',
    CREATE_USER: 'CREATE_USER',
    EDIT_USER: 'EDIT_USER',
    REMOVE_USER: 'REMOVE_USER',

    REPORT_SALES_BY_SECTOR: 'REPORT_SALES_BY_SECTOR',
    REPORT_SALES_BY_USER: 'REPORT_SALES_BY_USER',
    REPORT_RECEIVABLES_BY_PAYMENT_METHOD: 'REPORT_RECEIVABLES_BY_PAYMENT_METHOD',
    REPORT_RECEIVABLES_DETAILED: 'REPORT_RECEIVABLES_DETAILED',
    REPORT_PAID_RECEIVABLES_BY_USER: 'REPORT_PAID_RECEIVABLES_BY_USER',

    SHOW_TOTAL_SALE_LIST: 'SHOW_TOTAL_SALE_LIST',
    SHOW_TOTAL_RECEIVABLE_LIST: 'SHOW_TOTAL_RECEIVABLE_LIST',
};

export const CompanyPermission = {
    LIST_SERVICE: 'LIST_SERVICE',
    CREATE_SERVICE: 'CREATE_SERVICE',
    EDIT_SERVICE: 'EDIT_SERVICE',
    REMOVE_SERVICE: 'REMOVE_SERVICE',

    LIST_SECTOR: 'LIST_SECTOR',
    CREATE_SECTOR: 'CREATE_SECTOR',
    EDIT_SECTOR: 'EDIT_SECTOR',
    REMOVE_SECTOR: 'REMOVE_SECTOR',

    LIST_PAYMENT_TERM: 'LIST_PAYMENT_TERM',
    CREATE_PAYMENT_TERM: 'CREATE_PAYMENT_TERM',
    EDIT_PAYMENT_TERM: 'EDIT_PAYMENT_TERM',
    REMOVE_PAYMENT_TERM: 'REMOVE_PAYMENT_TERM',

    LIST_RECEIVABLE: 'LIST_RECEIVABLE',
    CREATE_RECEIVABLE: 'CREATE_RECEIVABLE',
    EDIT_RECEIVABLE: 'EDIT_RECEIVABLE',
    REMOVE_RECEIVABLE: 'REMOVE_RECEIVABLE',
    REVERSE_RECEIVABLE: 'REVERSE_RECEIVABLE',

    LIST_PAYMENT_METHOD: 'LIST_PAYMENT_METHOD',
    CREATE_PAYMENT_METHOD: 'CREATE_PAYMENT_METHOD',
    EDIT_PAYMENT_METHOD: 'EDIT_PAYMENT_METHOD',
    REMOVE_PAYMENT_METHOD: 'REMOVE_PAYMENT_METHOD',

    LIST_USER: 'LIST_USER',
    CREATE_USER: 'CREATE_USER',
    EDIT_USER: 'EDIT_USER',
    REMOVE_USER: 'REMOVE_USER',

    REPORT_SALES_BY_SECTOR: 'REPORT_SALES_BY_SECTOR',
    REPORT_SALES_BY_USER: 'REPORT_SALES_BY_USER',
    REPORT_RECEIVABLES_BY_PAYMENT_METHOD: 'REPORT_RECEIVABLES_BY_PAYMENT_METHOD',
    REPORT_RECEIVABLES_DETAILED: 'REPORT_RECEIVABLES_DETAILED',
    REPORT_PAID_RECEIVABLES_BY_USER: 'REPORT_PAID_RECEIVABLES_BY_USER',

    SHOW_TOTAL_SALE_LIST: 'SHOW_TOTAL_SALE_LIST',
    SHOW_TOTAL_RECEIVABLE_LIST: 'SHOW_TOTAL_RECEIVABLE_LIST',
};