import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone, adjustDateToServerTimeZone, calculateDaysDifference,
    formatDateTimeToString,
    formatDateToAmericanFormat,
    formatDateToString, getOnlyDate, UserPermission
} from "../../../utils/utils";
import {getAllPaymentMethods, getReceivableById, userHasPermission} from "../../../utils/requestUtils";
import Config from "../../../core/config";
import {useRecoilState} from "recoil";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import Loading from "../../../core/Loading";
import {loadingAtom} from "../../../atoms";
import {useReactToPrint} from "react-to-print";

const apiUrl = Config.apiUrl;
const ReportReceivables = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    const [receivables, setReceivables] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [totalValue, setTotalValue] = useState(0);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(-1);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const navigate = useNavigate();
    const [hasReportPermission, setReportPermission] = useState(undefined);

    const [sortConfig, setSortConfig] = useState({key: 'dueDate', direction: 'ascending'});
    const [sortedReceivables, setSortedReceivables] = useState([]);
    const componentRef = useRef();

    useEffect(() => {
        if (hasReportPermission === false) {
            navigate('/unauthorizedaccess');
        }
    }, [hasReportPermission]);


    const [loading, setLoading] = useRecoilState(loadingAtom);

    async function makeQuery() {
        try {

            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            setLoading(true);
            const url = `${apiUrl}/api/receivables/queryPaidReceivablesByPaymentDate?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&status=${1}&paymentMethodId=${selectedPaymentMethodId}`;
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            const updatedResponse = response.map(sale => ({
                ...sale,
                customerName: sale.customerName.toUpperCase(),
            }));
            setReceivables(updatedResponse);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        userHasPermission(UserPermission.REPORT_RECEIVABLES_DETAILED).then(userHasPermission => setReportPermission(userHasPermission));
        getAllPaymentMethods().then(paymentMethods => setPaymentMethods(paymentMethods));
        makeQuery();
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    useEffect(() => {
        setTotalValue(receivables.reduce((acc, obj) => acc + obj.value, 0));
    }, [receivables]);

    useEffect(() => {
        setSortedReceivables([...receivables].sort((a, b) => {
            const aValue = sortConfig.key.includes('.') ? a[sortConfig.key.split('.')[0]][sortConfig.key.split('.')[1]] : a[sortConfig.key];
            const bValue = sortConfig.key.includes('.') ? b[sortConfig.key.split('.')[0]][sortConfig.key.split('.')[1]] : b[sortConfig.key];

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        }));
    }, [receivables, sortConfig]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    const handleQuery = async (e) => {
        await makeQuery();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Fechamento de Caixa Detalhado',
        pageStyle: `
      @page {
        size: A4;
        margin: 20mm !important;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
        div, table, thead, tbody, tfoot, tr, th, td {
          overflow: visible !important;
        }
        table {
          width: 100%;
        }
        .print-button {
          display: none;
        }
      }
    `,
    });

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const onPayClick = async (id) => {
        const receivable = await getReceivableById(id);
        if (!window.confirm(`Deseja realmente ${!receivable.paymentDate ? "Baixar" : "Estornar"}?`)) {
            return;
        }
        if (receivable.paymentDate) {
            receivable.paymentDate = undefined;
            receivable.status = 0;
        } else {
            receivable.paymentDate = getOnlyDate(new Date());
            receivable.status = 1;
        }

        const url = apiUrl + '/api/receivables';
        setLoading(true);
        await makeRequest(url, 'PUT', {
            'Content-Type': 'application/json',
            'Useruuid':Cookies.get('userUUID')
        }, JSON.stringify(receivable));
        setLoading(false);
        await makeQuery();
    };

    function getBackgroundColor(receivable) {
        const currentDate = getOnlyDate(new Date());
        const dueDate = getOnlyDate(adjustDateToBrowserTimeZone(new Date(receivable.dueDate)));
        const errorColor = 'var(--error-color)';
        const warningColor = 'var(--warning-color)';
        const normalColor = 'var(--normal-color)';
        if (receivable.status === 0) {
            if (dueDate < currentDate)
                return errorColor;
            if (calculateDaysDifference(dueDate, currentDate) === 0) {
                return warningColor;
            }
        }
        return normalColor;
    }

    if(hasReportPermission === undefined) {
        return <Loading/>
    }

    return (
        <div ref={componentRef}>
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Fechamento de Caixa Detalhado</div>
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>


                    <div style={{display: 'flex', marginTop: '5px', alignItems: 'center'}}>
                        <div>
                        Data:
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />

                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />




                        </div>
                        </div>
                        <div style={{flexDirection: "column", alignItems: 'center'}}>

                            Forma de Pagamento
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <select value={selectedPaymentMethodId}
                                        onChange={(e) => setSelectedPaymentMethodId(e.target.value)}>
                                    <option value="-1">Todos</option>
                                    {paymentMethods.map((paymentMethod, index) => (
                                        <option key={index} value={paymentMethod.id}>
                                            {paymentMethod.name}
                                        </option>
                                    ))}
                                </select>
                                <button onClick={handleQuery}>Consultar</button>
                                <button onClick={handlePrint}>Imprimir</button>
                            </div>

                        </div>

                    </div>

            </div>

            <table style={{width: '1000px', maxHeight: '100%'}}>

                <tr style={{cursor: "pointer"}}>
                    <th onClick={() => handleSort('customerName')}>
                        {getSortIcon('customerName')}
                        Cliente
                    </th>
                    <th onClick={() => handleSort('description')}>
                        {getSortIcon('description')}
                        Descrição
                    </th>
                    <th onClick={() => handleSort('paymentMethod.name')}>
                        {getSortIcon('paymentMethod.name')}
                        Forma de Pagamento
                    </th>
                    <th style={{textAlign: 'center'}} onClick={() => handleSort('date')}>
                        {getSortIcon('date')}
                        Lançamento
                    </th>
                    <th style={{textAlign: 'center'}} onClick={() => handleSort('dueDate')}>
                        {getSortIcon('dueDate')}
                        Vencimento
                    </th>
                    <th style={{textAlign: 'center'}} onClick={() => handleSort('paymentDate')}>
                        {getSortIcon('paymentDate')}
                        Pagamento
                    </th>
                    <th style={{textAlign: 'right'}}onClick={() => handleSort('value')}>
                        {getSortIcon('value')}
                        Valor
                    </th>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {sortedReceivables.map((receivable, index) => (
                    <tr style={{backgroundColor: getBackgroundColor(receivable)}}>
                        <td>
                            <div key={index}>{receivable.customerName}</div>
                        </td>
                        <td>
                            <div key={index}>{receivable.description}</div>
                        </td>
                        <td>
                            <div key={index}>{receivable.paymentMethod ? receivable.paymentMethod.name : ''}</div>
                        </td>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{formatDateToString(new Date(receivable.date))}</div>
                        </td>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{formatDateToString(adjustDateToServerTimeZone(new Date(receivable.dueDate)))}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'center'}}>{receivable.paymentDate ? formatDateToString(new Date(receivable.paymentDate)) : "Pendente"}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'right'}}>{receivable.value.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>

                    </tr>
                ))}
                </tbody>
                {receivables.length === 0 && (<tfoot>{loading ? 'Carregando...' : `Nenhum item encontrado.`}</tfoot>)}
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label">Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</div>
            </div>

        </div>
    );
};

export default ReportReceivables;
