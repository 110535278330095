import React from 'react';
import {BrowserRouter, Route, Router, Routes} from "react-router-dom";

import {useRecoilState} from "recoil";
import Login from "./Login";
import Loading from "./core/Loading";
import {loadingAtom} from "./atoms";
import Menu from "./Menu";
import CentralizedView from "./components/common/CentralizedView";
import SaleList from "./components/sale/SaleList";
import SectorList from "./components/sector/SectorList";
import SectorManager from "./components/sector/SectorManager";
import SaleManager from "./components/sale/SaleManager";
import PaymentMethodList from "./components/paymentMehod/PaymentMethodList";
import PaymentMethodManager from "./components/paymentMehod/PaymentMethodManager";
import ReceivableList from "./components/receivable/ReceivableList";
import ReceivableManager from "./components/receivable/ReceivableManager";
import ReportSalesBySector from "./components/reports/salesBySector/ReportSalesBySector";
import ReportSalesByUser from "./components/reports/salesByUser/ReportSalesByUser";
import ReportReceivablesByPaymentMethod
    from "./components/reports/receivablesByPaymentMethod/ReportReceivablesByPaymentMethod";
import ReportReceivables from "./components/reports/receivables/ReportReceivables";
import UserManager from "./components/user/UserManager";
import UserList from "./components/user/UserList";
import UnauthorizedAccess from "./core/UnauthorizedAccess";
import PaymentTermList from "./components/paymentTerm/PaymentTermList";
import PaymentTermManager from "./components/paymentTerm/PaymentTermManager";
import CompanyList from "./components/company/CompanyList";
import CompanyManager from "./components/company/CompanyManager";
import ReportPaidReceivablesByUser from "./components/reports/paidReceivablesByUser/ReportPaidReceivablesByUser";


const AppRouter = (props) => {

    const {customerKeyValid} = props;
    const [currentLoading] = useRecoilState(loadingAtom);

    return (
        <BrowserRouter>
            {currentLoading && (<Loading/>)}
            <Routes>
                <Route path="/login"
                       element={<Login/>}/>
                
                <Route path="/admin"
                       element={<Menu><CentralizedView></CentralizedView></Menu>}/>
                <Route path="/admin/companylist"
                       element={<Menu><CentralizedView><CompanyList/></CentralizedView></Menu>}/>
                <Route path="/admin/company"
                       element={<Menu><CentralizedView><CompanyManager/></CentralizedView></Menu>}/>
                <Route path="/admin/userlist"
                       element={<Menu><CentralizedView><UserList/></CentralizedView></Menu>}/>
                <Route path="/admin/user"
                       element={<Menu><CentralizedView><UserManager/></CentralizedView></Menu>}/>

                <Route path="/app"
                       element={<Menu><CentralizedView></CentralizedView></Menu>}/>
                <Route path="/app/salelist"
                       element={<Menu><CentralizedView><SaleList/></CentralizedView></Menu>}/>
                <Route path="/app/sale"
                       element={<Menu><CentralizedView><SaleManager/></CentralizedView></Menu>}/>
                <Route path="/app/sectorlist"
                       element={<Menu><CentralizedView><SectorList/></CentralizedView></Menu>}/>
                <Route path="/app/sector"
                       element={<Menu><CentralizedView><SectorManager/></CentralizedView></Menu>}/>
                <Route path="/app/paymenttermlist"
                       element={<Menu><CentralizedView><PaymentTermList/></CentralizedView></Menu>}/>
                <Route path="/app/paymentterm"
                       element={<Menu><CentralizedView><PaymentTermManager/></CentralizedView></Menu>}/>
                <Route path="/app/userlist"
                       element={<Menu><CentralizedView><UserList/></CentralizedView></Menu>}/>
                <Route path="/app/user"
                       element={<Menu><CentralizedView><UserManager/></CentralizedView></Menu>}/>
                <Route path="/app/paymentmethodlist"
                       element={<Menu><CentralizedView><PaymentMethodList/></CentralizedView></Menu>}/>
                <Route path="/app/reports/salesbysector"
                       element={<Menu><CentralizedView><ReportSalesBySector/></CentralizedView></Menu>}/>
                <Route path="/app/reports/salesbyuser"
                       element={<Menu><CentralizedView><ReportSalesByUser/></CentralizedView></Menu>}/>
                <Route path="/app/reports/paidreceivablebybyuser"
                       element={<Menu><CentralizedView><ReportPaidReceivablesByUser/></CentralizedView></Menu>}/>
                <Route path="/app/reports/receivablesbypaymentmethod"
                       element={<Menu><CentralizedView><ReportReceivablesByPaymentMethod/></CentralizedView></Menu>}/>
                <Route path="/app/reports/receivables"
                       element={<Menu><CentralizedView><ReportReceivables/></CentralizedView></Menu>}/>
                <Route path="/app/paymentmethod"
                       element={<Menu><CentralizedView><PaymentMethodManager/></CentralizedView></Menu>}/>
                <Route path="/app/receivablelist"
                       element={<Menu><CentralizedView><ReceivableList/></CentralizedView></Menu>}/>
                <Route path="/app/receivable"
                       element={<Menu><CentralizedView><ReceivableManager/></CentralizedView></Menu>}/>
                <Route path="/unauthorizedaccess"
                       element={<Menu><CentralizedView><UnauthorizedAccess/></CentralizedView></Menu>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;

